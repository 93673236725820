import React from "react";

/**
 * @return {JSX.Element} a 404 page component
 * @constructor
 */
export function NotFoundPage() {
    return (
        <div>
            <h1>404</h1>
            <h3>Sorry, the page was not found.</h3>
        </div>
    );
}
