import React, {useEffect, useState} from "react";
import {useParams} from "react-router";
import {Loader} from "../loader";
import ErrorBalloon from "../error-balloon";
import {DefaultService, Stacktrace as StacktraceDTO} from "../../server";
import {Stacktrace} from "../single-stacktrace";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";

const FIVE_SECONDS = 5 * 1000;

/**
 * Shows a stacktrace for a dump with a specific dump ID.
 *
 * <p>Also, includes metadata of the Platinum build associated with this dump.
 *
 * @return {JSX.Element} a component with stacktrace
 * @constructor
 */
export function StackTrace() {
    const [error, setError] = useState("");
    const params = useParams();

    const [stacktraces, setStacktraces] = useState<Array<StacktraceDTO>>([]);

    const fetchAll = async () => {
        if (params.ids) {
            DefaultService.serverStacktraceGet(params.ids).then((response) => {
                setStacktraces(response);
            });
        }
    };

    const fetchSingle = async (id: string) => {
        DefaultService.serverStacktraceGet(id).then((response) => {
            if (response.length > 0) {
                const stacktrace = response[0];
                const allExceptThis = stacktraces.filter((it) => it.dumpId != id);
                setStacktraces([stacktrace, ...allExceptThis]);
            }
        }, (error) => {
            setError(error.message);
        });
    };

    useEffect(() => {
        fetchAll();
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            const pendingItems = stacktraces.filter((it) => it.status === "pending");
            if (pendingItems.length == 0) {
                clearInterval(interval);
            }
            pendingItems.forEach((it) => {
                // This is a generated code. We know that dumpId is always present.
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                fetchSingle(it.dumpId!);
            });
        }, FIVE_SECONDS);
        return () => clearInterval(interval);
    });

    if (stacktraces.length > 0) {
        return (
            <Tab.Container id="left-tabs-example" defaultActiveKey={stacktraces[0].dumpId}>
                <Row>
                    <Col sm={3}>
                        <Nav variant="pills" className="flex-column">
                            {stacktraces.map((stacktrace, index) =>
                                (
                                    <Nav.Item key={stacktrace.dumpId}>
                                        <Nav.Link eventKey={stacktrace.dumpId}>
                                            #{index + 1}
                                        </Nav.Link>
                                    </Nav.Item>
                                )
                            )}
                        </Nav>
                    </Col>
                    <Col sm={9}>
                        <Tab.Content>
                            {stacktraces.map((stacktrace) =>
                                (
                                    <Tab.Pane key={stacktrace.dumpId} eventKey={stacktrace.dumpId}>
                                        <Stacktrace value={stacktrace}/>
                                    </Tab.Pane>
                                )
                            )}
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        );
    }
    if (error) {
        return (
            <ErrorBalloon errorMessage={error}/>
        );
    }
    return (
        <div><Loader/></div>
    );
}
