import React, {createContext} from "react";
import "./index.css";
import App from "./App";
import {initializeApp} from "firebase/app";
import {Auth, getAuth} from "firebase/auth";
import {OpenAPI} from "./server";
import Environment from "./environment";
// eslint-disable-next-line import/no-unresolved
import "bootstrap/dist/css/bootstrap.min.css";
import {createRoot} from "react-dom/client";

initializeApp(Environment.firebaseConfig);

const auth: Auth = getAuth();

const getAuthToken = async () => {
    const currentUser = getAuth().currentUser;
    if (currentUser) {
        return currentUser.getIdToken(true);
    }
    throw new Error("Unexpected API call from an unauthorized used");
};

// Settings for the generated backend API client.
OpenAPI.TOKEN = getAuthToken;
OpenAPI.BASE = Environment.backendUrl;

export const AuthContext = createContext<Auth>(auth);
const container = document.getElementById("root");
// The container node is always there, see public/index.html.
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
root.render(
    <AuthContext.Provider value={auth}>
        <React.StrictMode>
            <App/>
        </React.StrictMode>
    </AuthContext.Provider>
);
