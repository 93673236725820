import React, {useEffect, useState} from "react";
import {DefaultService, Stacktrace as StacktraceDTO} from "../../server";
import {getAuth} from "firebase/auth";
import {Link, useLocation} from "react-router-dom";
import {Loader} from "../loader";
import {Button} from "react-bootstrap";

/**
 * Displays the user's uploading history. It renders the table with all stacktraces user has analyzed.
 *
 * The table contains the uploading date which is the link to the full stacktrace text at the same time,
 * the user's operating system, architecture, and analyzing status.
 * Pagination functionality has been added for comfortable viewing by dividing all stacktraces into groups by 10.
 *
 * The empty table will be generated if there are no stacktraces to show.
 */
export function UploadingHistory() {
    const [isWorking, setIsWorking] = useState(false);
    const [hasMoreEntities, setHasMoreEntities] = useState(true);
    const [stacktraces, setStacktraces] = useState<Array<StacktraceDTO>>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPages, setItemsPerPages] = useState(10);
    const location = useLocation();

    const fetchAll = async () => {
        const currentUser = getAuth().currentUser;
        if (currentUser?.email) {
            setIsWorking(true);
            DefaultService.serverStacktraceGetAll(currentUser.email, currentPage, itemsPerPages)
                .then((response) => {
                    if (response.result && response.hasMoreEntities !== undefined) {
                        response.result.sort((a, b) => (a.whenUploaded && b.whenUploaded)
                            ? -1 * a.whenUploaded.localeCompare(b.whenUploaded) : 0
                        );

                        setHasMoreEntities(response.hasMoreEntities);
                        setStacktraces(response.result);
                    }
                    setIsWorking(false);
                });
        }
    };

    const getStatusEmoji = (status: string | undefined) => {
        if (status && status in ["analyzed", "deciphered"]) {
            return "✅";
        }
        if (status === "error") {
            return "❌";
        }
        return "🕑";
    };

    const formatDate = (date: string | undefined) => {
        if (!date) {
            throw new Error("Date doesn't exist.");
        }

        const dateTimeFormat = new Intl.DateTimeFormat("en", {
            year: "numeric",
            month: "long",
            day: "numeric",
        });

        return dateTimeFormat.format(new Date(date));
    };

    const getOsIcon = (os: string | undefined) => {
        if (os === "mac") {
            return "apple";
        }
        if (os === "windows") {
            return "windows";
        }
        return "linux";
    };

    useEffect(() => {
        fetchAll();
    }, [location, currentPage, itemsPerPages]);

    if (isWorking) {
        return (
            <div><Loader/></div>
        );
    }

    return (
        <div className="mx-auto">
            <h3>Uploading history</h3>
            <table className="table">
                <thead>
                <tr>
                    <th>#</th>
                    <th className="text-center">Date</th>
                    <th className="text-center">Status</th>
                    <th className="text-center">OS</th>
                    <th className="text-center">CPU architecture</th>
                </tr>
                </thead>
                <tbody>
                {stacktraces.map((stacktrace, index) =>
                    (<tr key={stacktrace.dumpId}>
                            <th scope="row">{index + 1}</th>
                            <td className="text-center"><Link to={"/stacktrace/" + stacktrace.dumpId} target="_blank">
                                {formatDate(stacktrace.whenUploaded)}</Link></td>
                            <td className="text-center">
                                {getStatusEmoji(stacktrace.status)}</td>
                            <td className="text-center">
                                <img className="icon"
                                     src={getOsIcon(stacktrace.buildData?.platform) + ".png"} alt="Platform logo."/>
                            </td>
                            <td className="text-center">{stacktrace.buildData?.architecture}</td>
                        </tr>
                    )
                )}
                </tbody>
            </table>
            <div>
                {stacktraces.length > 0 && (
                    <div className="pagination-container">
                        <div className="pagination-controls">
                            <Button as="input" type="submit" value="Previous"
                                    onClick={() => setCurrentPage(currentPage - 1)}
                                    disabled={currentPage === 1}/>
                            <span className="pagination-num">{currentPage}</span>
                            <Button as="input" type="submit" value="Next"
                                    onClick={() => setCurrentPage(currentPage + 1)}
                                    disabled={!hasMoreEntities}
                            />
                        </div>
                        <div className="rows-per-page">
                            <span className="label"> Rows per page: </span>
                            <select
                                className="form-select-sm"
                                value={itemsPerPages}
                                onChange={(e) => {
                                    setItemsPerPages(parseInt(e.target.value));
                                }}
                                aria-label={`Rows per page ${itemsPerPages}`}
                            >
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="50">50</option>
                            </select>
                        </div>
                    </div>
                )}
            </div>

        </div>
    );
}
