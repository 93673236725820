import {Alert, Container} from "react-bootstrap";
import React, {Component} from "react";


type ErrorBalloonProps = { errorMessage: string }

/**
 * A yellow warning panel with customizable text.
 */
export default class ErrorBalloon extends Component<ErrorBalloonProps, any> {
    constructor(props: ErrorBalloonProps) {
        super(props);
    }

    render() {
        return (
            <Alert variant="warning" dismissible>
                <Container>
                    <p>{this.props.errorMessage}</p>
                </Container>
            </Alert>
        );
    }
}
