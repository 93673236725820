type EnvironmentType = "local" | "staging" | "production"

const envName = (): EnvironmentType => {
    const env = process.env.REACT_APP_ENV;
    if (env === "staging") {
        return "staging";
    } else if (env === "production") {
        return "production";
    }
    return "local";
};

const environments: Record<EnvironmentType, Environment> = {
    "production": {
        firebaseConfig: {
            apiKey: "AIzaSyCSdVPumy4GB1jxWEswdn_EhXgJe-HtZIM",
            authDomain: "dump-buster.firebaseapp.com",
            projectId: "dump-buster",
            storageBucket: "dump-buster.appspot.com",
            messagingSenderId: "712272161749",
            appId: "1:712272161749:web:52a0066da6b1e64d776b5c",
            measurementId: "G-331KBJXVPN",
        },
        backendUrl: "https://dump-buster.ew.r.appspot.com",
    },
    "staging": {
        firebaseConfig: {
            apiKey: "AIzaSyBct9E86B17KKY8GjLVSLjPxifshpzJ4AE",
            authDomain: "dump-buster-staging.firebaseapp.com",
            projectId: "dump-buster-staging",
            storageBucket: "dump-buster-staging.appspot.com",
            messagingSenderId: "417331093709",
            appId: "1:417331093709:web:830bdd84b8ef2fa3bd8984",
        },
        backendUrl: "https://dump-buster-staging.ew.r.appspot.com",
    },
    "local": {
        firebaseConfig: {
            apiKey: "AIzaSyBct9E86B17KKY8GjLVSLjPxifshpzJ4AE",
            authDomain: "dump-buster-staging.firebaseapp.com",
            projectId: "dump-buster-staging",
            storageBucket: "dump-buster-staging.appspot.com",
            messagingSenderId: "417331093709",
            appId: "1:417331093709:web:830bdd84b8ef2fa3bd8984",
        },
        backendUrl: "http://127.0.0.1:5000",
    },
};


type Environment = {

    /**
     * The configuration of the Firebase app.
     */
    firebaseConfig: Record<string, string>,

    /**
     * The URL of the backend service.
     */
    backendUrl: string,
}


export default environments[envName()];
