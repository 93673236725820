import React, {Component} from "react";
import {Metadata} from "../metadata";
import {Stacktrace as StacktraceDTO} from "../../server";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

type StacktraceProps = { value: StacktraceDTO }

export class Stacktrace extends Component<StacktraceProps, unknown> {
    constructor(props: StacktraceProps) {
        super(props);
    }

    render() {
        const value = this.props.value;
        if (value.status === "pending") {
            const now = dayjs.utc();
            const whenUploaded = dayjs(value.whenUploaded);
            const minutes = whenUploaded.diff(now, "minute");
            return (<div>
                    <p>We&lsquo;re still processing the stacktrace. Once ready, we will show the result
                        automatically.</p>
                    <p>The file has been in processing for {minutes} minutes.</p>
                </div>
            );
        }
        if (value.buildData || value.fullText || value.errorMessage) {
            return (
                <div>
                    <div>
                        {value.buildData &&
                            <div>
                                <h3>Metadata</h3>
                                <Metadata metadata={value.buildData}/>
                            </div>
                        }
                        {value.errorMessage &&
                            <div>
                                <h3>Couldn&lsquo;t analyze this dump, sorry</h3>
                                <pre>{value.errorMessage}</pre>
                            </div>
                        }
                        {value.fullText &&
                            <div>
                                <h3>Stacktrace <button className="btn"
                                                       onClick={() => {
                                                           if (value.dumpId && value.fullText) {
                                                               this.downloadStackTrace(value.dumpId, value.fullText);
                                                           }
                                                       }}>
                                    <span>&#x2193;</span>
                                </button>
                                </h3>
                                <pre>
                                {value.fullText}
                            </pre>
                            </div>
                        }
                    </div>
                </div>
            );
        }
        throw new Error("Should not happen");
    }

    /**
     * Triggers the download of a stacktrace as a txt file.
     *
     * @param {string} dumpId
     * @param {string} content
     */
    downloadStackTrace(dumpId: string, content: string) {
        const blob = new Blob([content], {type: "text/plain"});
        const elem = window.document.createElement("a");
        const currentDate = new Date().toISOString().slice(0, 10);
        elem.href = window.URL.createObjectURL(blob);
        elem.download = `stacktrace-${dumpId}-${currentDate}`;
        elem.click();
    }
}
