import {PlatinumMetadata} from "../../server";
import React, {Component} from "react";

const TEAMCITY_HOST_URL = "https://teamcity.teamdev-integration.dev";
const GITHUB_PLATINUM_URL = "https://github.com/TeamDev-IP/Platinum";


const teamcityBuildLink = (metadata: PlatinumMetadata) => {
    const buildId = metadata["ciBuild"];
    const configurationName = metadata["ciConfiguration"];
    return `${TEAMCITY_HOST_URL}/viewLog.html?buildId=${buildId}&buildTypeId=${configurationName}`;
};

const githubCommitLink = (metadata: PlatinumMetadata) => {
    const commit = metadata["commit"];
    return `${GITHUB_PLATINUM_URL}/commit/${commit}`;
};

/**
 * @param metadata an object containing info about a Platinum build (commit, platform, etc.)
 * @returns {JSX.Element} a component containing info about a Platinum build
 * @constructor
 */
type MetadataProps = { metadata: PlatinumMetadata }

export class Metadata extends Component<MetadataProps, unknown> {
    private linkStyle = {marginLeft: 5};

    constructor(props: MetadataProps) {
        super(props);
    }

    render() {
        return (
            <div>
                {this.props.metadata &&
                    <div>
                        <p><b>Platform:</b> {this.props.metadata["platform"]}</p>
                        <p><b>Architecture:</b> {this.props.metadata["architecture"]}</p>
                        <p><b>TeamCity Build Link:</b>
                            <a style={this.linkStyle} target="_blank"
                               href={teamcityBuildLink(this.props.metadata)} rel="noreferrer">
                                Click
                            </a>
                        </p>
                        <p><b>GitHub Commit Link:</b>
                            <a style={this.linkStyle} target="_blank"
                               href={githubCommitLink(this.props.metadata)} rel="noreferrer">
                                Click
                            </a>
                        </p>
                    </div>
                }
            </div>
        );
    }
}


type MetadataTableProps = { metadataList: [PlatinumMetadata] }

/**
 * @param metadataList an object containing the list of metadata items
 * @returns {JSX.Element} a table showing all metadata objects
 * @constructor
 */
export class MetadataTable extends Component<MetadataTableProps, unknown> {
    constructor(props: MetadataTableProps) {
        super(props);
    }

    render() {
        return (
            <div>
                {this.props.metadataList &&
                    <table className="table table-sm">
                        <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Platform</th>
                            <th scope="col">Architecture</th>
                            <th scope="col">Links</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.props.metadataList.map((metadata, index) =>
                            <tr key={index}>
                                <th scope="row">{index + 1}</th>
                                <td>{metadata["platform"]}</td>
                                <td>{metadata["architecture"]}</td>
                                <td>
                                    <button type="button" className="btn btn-link">
                                        <a href={githubCommitLink(metadata)}>GitHub</a>
                                    </button>
                                    <button type="button" className="btn btn-link">
                                        <a href={teamcityBuildLink(metadata)}>TeamCity</a>
                                    </button>
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                }
            </div>
        );
    }
}
