import React, {useEffect, useState} from "react";
import {Navbar, NavDropdown} from "react-bootstrap";
import {ReactComponent as Logo} from "./logo.svg";
import {ReactComponent as Ghost} from "./ghost.svg";
import {getAuth, signOut} from "firebase/auth";
import {Link, useLocation} from "react-router-dom";


/**
 * Displays the sidebar that includes the application header, the content table, and a user profile icon.
 * The sidebar supports extending by adding additional items.
 *
 * @return {JSX.Element} a component with sidebar menu.
 * @constructor
 */
export function SideBar() {
    const [activeIndex, setActiveIndex] = useState(0);
    const location = useLocation();
    const auth = getAuth();
    const user = auth.currentUser;
    const photoURL = user?.photoURL;
    const avatar = photoURL
        ? <img src={photoURL} alt={"avatar"} className={"rounded-circle avatar"}/>
        : <Ghost className={"avatar"}/>;
    const signUserOut = () => signOut(auth);
    const sidebarItems = [
        {
            display: "Home",
            to: "/",
        },
        {
            display: "History",
            to: "/history",
        },
    ];

    useEffect(() => {
        const curPath = window.location.pathname;
        const activeItem = sidebarItems.findIndex((item) => item.to === curPath);
        setActiveIndex(curPath.length === 0 ? 0 : activeItem);
    }, [location]);

    return (
        <div className="d-flex flex-column flex-shrink-0 p-3 bg-light" style={{height: "100vh"}}>
            <Navbar.Brand href="/" style={{fontSize: 32}}>
                <Logo style={{marginRight: 10}}/>
                DumpBuster
            </Navbar.Brand>
            <hr/>
            <ul className="nav nav-pills flex-column mb-auto">
                {
                    sidebarItems.map((item, index) => (
                        <li className="nav-item" key={index}>
                            <Link to={item.to} className={`nav-link ${activeIndex === index ? "active" : "link-dark"}`}>
                                {item.display}
                            </Link>
                        </li>
                    ))
                }
            </ul>
            <hr/>
            {
                user &&
                <NavDropdown title={avatar}>
                    <NavDropdown.Item onClick={signUserOut}>Sign Out</NavDropdown.Item>
                </NavDropdown>
            }
        </div>
    );
}
