import React, {useEffect, useState} from "react";
import {Loader} from "../loader";
import {Metadata} from "../metadata";
import ErrorBalloon from "../error-balloon";
import {DefaultService, PlatinumMetadata} from "../../server";

/**
 * @return {JSX.Element} a component containing a list with all {@link Metadata metadata}
 * @constructor
 */
export function MetadataList() {
    const [isLoading, setIsLoading] = useState(false);
    const [metadataList, setMetadataList] = useState<Array<PlatinumMetadata>>([]);
    const [error, setError] = useState("");

    useEffect(() => {
        const load = async () => {
            setIsLoading(true);
            try {
                DefaultService.serverMetadataGetAll().then((result) => {
                    setMetadataList(result);
                }, (error) => {
                    setError(error);
                });
            } catch (e: any) {
                setError(e.errorMessage);
            } finally {
                setIsLoading(false);
            }
        };
        load();
    }, []);

    return (
        <div>
            {isLoading
                ? <Loader/>
                :
                <div>
                    {error && <ErrorBalloon errorMessage={error}/>}
                    <h3>All metadata</h3>
                    <hr/>
                    {metadataList.map((metadata, index) =>
                        <div key={index}>
                            <Metadata metadata={metadata}/>
                            <hr/>
                        </div>
                    )}
                </div>
            }
        </div>
    );
}
