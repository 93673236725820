import {Button, Form, FormControl, FormGroup} from "react-bootstrap";
import React, {useState} from "react";
import {Loader} from "../loader";
import ErrorBalloon from "../error-balloon";
import {DefaultService, DumpFileIdMap} from "../../server";

/**
 * An upload form for dump files.
 *
 * <p>Accepts multiple files.
 *
 * @return {JSX.Element} a form for uploading a dump file to analyze
 * @constructor
 */
export function DumpUploadForm() {
    const [isWorking, setIsWorking] = useState(false);
    const [uploadError, setUploadError] = useState<string | null>(null);

    const sendDump = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setIsWorking(true);
        const {files} = (event.target as HTMLFormElement)[0] as HTMLFormElement;
        try {
            DefaultService.serverDumpAnalyze({
                crashDump: [...files],
            }).then((result) => {
                setIsWorking(false);
                const idMap = result as DumpFileIdMap;
                const ids = idMap.map(({dumpId}) => {
                    return dumpId;
                }).join(",");
                window.location.assign(`/stacktrace/${ids}`);
            }).catch((error) => {
                setIsWorking(false);
                console.error("Failed to analyze the dump files", error);
                setUploadError(error);
            });
        } catch (e: any) {
            // We're sure it's Error.
            console.error(e, e.stack);
            setUploadError(e.message);
        }
    };

    if (isWorking) {
        return (
            <div><Loader/></div>
        );
    }
    return (
        <div>
            <>
                <Form onSubmit={sendDump}>
                    <FormGroup controlId="formFileLg" className="mb-3">
                        <h3>Select a .dmp file</h3>
                        <FormControl style={{marginTop: 20}} type="file" size="lg" multiple/>
                    </FormGroup>
                    <Button as="input" type="submit" value="Analyze"/>
                </Form>
                {uploadError &&
                    <ErrorBalloon errorMessage={uploadError}/>
                }
            </>
        </div>
    );
}
