import React from "react";
import {ReactComponent as GearLoader} from "./loader.svg";

/**
 * A custom loader that is displayed when the application performs a synchronous time-consuming action (server
 * requests, authentication).
 *
 * @return {JSX.Element} a loader component
 * @constructor
 */
export function Loader() {
    return (
        <GearLoader style={{
            position: "absolute", left: "50%", top: "50%",
            transform: "translate(-50%, -50%)",
        }}/>
    );
}
